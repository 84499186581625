<template>
    <div class="color-picker">
        <v-menu offset-y :close-on-content-click="false">
            <template v-slot:activator="{ on, attrs }">
                <div v-bind="attrs" v-on="on" class="color" :style="'background-color: ' + item[fieldname] + ';'"></div>
            </template>
            <v-list>
                <v-list-item>
                    <v-color-picker mode="hexa" dot-size="25" swatches-max-height="200" v-model="item[fieldname]"></v-color-picker>
                </v-list-item>
            </v-list>
        </v-menu>
    </div>
</template>
<script>
export default {
    name: 'ColorPicker',
    props: ['item', 'fieldname'],

}
</script>