<template>
    <div class="class-options rectangle">
        <div class="sections" v-if="item">
        	<!-- Background -->
            <div class="section">
                <div class="label strong">Background</div>
                <!-- Color -->
                <div class="label">Color:</div>
                <color-picker :item="item" fieldname="fill" />
            </div>
            <!-- Border -->
            <div class="section">
                <div class="label strong">Border</div>
                <!-- Color -->
                <div class="label">Color:</div>
                <color-picker :item="item" fieldname="stroke" />
                <!-- Size -->
                <div class="label">Size:</div>
                <v-text-field height="0px" hide-details type="number" step="10" min="0" max="99" dense flat class="field-font-size no-line" v-model.number="item.strokeWidth" />
                <!-- Corners -->
                <div class="label">Corners:</div>
                <v-text-field height="0px" hide-details type="number" step="10" min="0" max="300" dense flat class="field-font-size no-line" v-model.number="item.cornerRadius" />
            </div>
            <!-- Shadow -->
            <div class="section">
            	<div class="label strong">Shadow</div>
            	<!-- Color -->
                <div class="label">Color:</div>
            	<color-picker :item="item" fieldname="shadowColor" />
            	<!-- Blur -->
                <div class="label">Blur:</div>
                <v-text-field height="0px" hide-details type="number" step="10" min="0" max="99" dense flat class="field-font-size no-line" v-model.number="item.shadowBlur" />
                <!-- Offset X -->
                <div class="label">X:</div>
                <v-text-field height="0px" hide-details type="number" step="10" min="0" max="99" dense flat class="field-font-size no-line" v-model.number="item.shadowOffsetX" />
                <!-- Offset Y -->
                <div class="label">Y:</div>
                <v-text-field height="0px" hide-details type="number" step="10" min="0" max="99" dense flat class="field-font-size no-line" v-model.number="item.shadowOffsetY" />
                <!-- Offset Y -->
                <div class="label">Opacity:</div>
                <v-text-field height="0px" hide-details type="number" step="0.1" min="0" max="1" dense flat class="field-font-size no-line" v-model.number="item.shadowOpacity" />
            </div>
        </div>
    </div>
</template>
<script>
import ColorPicker from './components/ColorPicker.vue';
export default {
    name: 'RectangleShapeOptions',
    props: ['selected'],
    components: {
        ColorPicker,
    },
    data() {
        return {
            item: null,
        }
    },
    watch: {
        'selected': {
            handler() {
                this.item = null;
                this.$nextTick(() => {
                    this.item = this.selected;
                });

            }
        },
        'item.strokeWidth': {
            handler(newStrokeWidth) {

            }
        }
    },
    mounted() {
        this.item = this.selected;
    },
    methods: {

    }
}
</script>