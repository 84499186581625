let Scene = [{
        component: 'v-rect',
        listening: false,
        name: 'Background',
        rotation: 0,
        x: 0,
        y: 0,
        fill: '#fff',
        width: 99999,
        height: 999999,
    },
    {
        component: 'v-rect',
        type: 'rectangle',
        name: 'Rectangle1',
        rotation: 0,
        x: 10,
        y: 810,
        fill: '#FF43C7FF',
        stroke: '#000',
        cornerRadius: 0,
        strokeWidth: 0,
        width: 900,
        height: 900,
        draggable: true,
        shadowColor: '#000000',
        shadowBlur: 10,
        shadowOffsetX: 40,
        shadowOffsetY: 40,
        shadowOpacity: 1,
    },
    {
        component: 'v-circle',
        type: 'circle',
        name: 'Circle1',
        x: 800,
        y: 800,
        radius: 350,
        fill: '#1AA7A7FF',
        stroke: '#000',
        strokeWidth: 0,
        draggable: true,
        shadowColor: '#000000',
        shadowBlur: 50,
        shadowOffsetX: 40,
        shadowOffsetY: 40,
        shadowOpacity: 1,
    },
    {
        component: 'v-text',
        type: 'text',
        name: 'Text',
        x: 1200,
        y: 200,
        text: 'Simple Text',
        fontSize: 250,
        fontFamily: 'Calibri',
        verticalAlign: 'left',
        fontStyle: 'bold',
        textDecoration: '',
        fill: '#FF43C7FF',
        stroke: '#000',
        strokeWidth: 0,
        draggable: true,
        keepRatio: true,
        shadowColor: '#000000',
        shadowBlur: 20,
        shadowOffsetX: 0,
        shadowOffsetY: 0,
        shadowOpacity: 0,
    },
    {
        component: 'v-text',
        type: 'text',
        name: 'Text',
        fill: 'red',
        x: 1200,
        y: 600,
        text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam congue eros a ornare mollis. Pellentesque hendrerit elit ac laoreet porta. Nam sit amet ligula id magna egestas mattis. Maecenas eget tortor justo. Suspendisse porta lorem id dictum pretium. Sed fringilla et orci fermentum finibus. Cras tincidunt commodo orci vitae ultricies.',
        verticalAlign: 'left',
        width: 1200,
        fontSize: 90,
        fontFamily: 'System',
        fontStyle: '',
        textDecoration: '',
        fill: '#000000FF',
        stroke: '#000',
        strokeWidth: 0,
        draggable: true,
        keepRatio: true,
        shadowColor: '#000000',
        shadowBlur: 20,
        shadowOffsetX: 0,
        shadowOffsetY: 0,
        shadowOpacity: 0,
    },
    {
        component: 'v-line',
        type: 'line',
        name: 'Line',
        points: [0, 0, 500, 0],
        stroke: '#FF43C7FF',
        strokeWidth: 50,
        x: 1200,
        y: 500,
        draggable: true,
        shadowColor: '#000000',
        shadowBlur: 0,
        shadowOffsetX: 40,
        shadowOffsetY: 40,
        shadowOpacity: 0,
    },
    {
        component: 'v-star',
        type: 'star',
        name: 'Star',
        x: 400,
        y: 500,
        numPoints: 6,
        innerRadius: 350,
        outerRadius: 200,
        fill: '#FFEC00FF',
        stroke: '#000',
        strokeWidth: 30,
        draggable: true,
        shadowColor: '#000000',
        shadowBlur: 10,
        shadowOffsetX: 40,
        shadowOffsetY: 40,
        shadowOpacity: 1,
    },
    {
        component: 'v-regular-polygon',
        type: 'polygon',
        name: 'Polygon',
        x: 400,
        y: 900,
        sides: 6,
        radius: 350,
        fill: '#615BFF6A',
        stroke: '#000',
        strokeWidth: 30,
        draggable: true,
        shadowColor: '#000000',
        shadowBlur: 10,
        shadowOffsetX: 40,
        shadowOffsetY: 40,
        shadowOpacity: 1,
    },
    {
        component: 'v-image',
        type: 'image',
        name: 'image1',
        url: "/yoda.jpg",
        x: 1000,
        y: 1200,
        width: 1000,
        height: 1000,
        stroke: '#000000',
        opacity: 1,
        strokeWidth: 0,
        draggable: true,
        keepRatio: true,
        shadowColor: '#000000',
        shadowBlur: 20,
        shadowOffsetX: 0,
        shadowOffsetY: 0,
        shadowOpacity: 0,
    }
];

export default Scene;