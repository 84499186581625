<template>
    <v-dialog
        class="app-modal"
        v-model="show"
        persistent
        max-width="620"
    >
      <v-card class="app-modal__card">
            <div class="app-modal__card--close">
               <inline-svg @click.stop="show = false" src="/img/modal-close.svg" height="24" width="24" fill="#2e4dd4"/>
            </div>
            <div class="app-modal__card--content">
                <h1 class="app-modal__title">{{title}}</h1>
                <div class="app-modal__card--rating">
                    <v-rating
                        v-model='rating'
                        background-color="#b4b7b9"
                        color="#ffbc6e"
                        empty-icon="mdi-star"
                        full-icon="mdi-star"
                        hover
                        dense
                        length="5"
                        size="45"
                    ></v-rating>
                    <span>
                        {{rating?rating:0}}/5
                    </span>
                </div>
            </div>
            <div class="app-modal__card--actions">
                <v-btn class="app-modal__btn" elevation="4" rounded color="#fff" @click.stop="setRating">
                    <inline-svg :src="'/img/'+btnIcon+'.svg'" :height="btnIconSize" :width="btnIconSize" fill="#2e4dd4"/>
                    <span>{{btnTitle}}</span>
                </v-btn>
            </div>
      </v-card>
    </v-dialog>
</template>
<script>
import InlineSvg from 'vue-inline-svg';
export default {
    props:['title','btnIcon', 'btnIconSize','btnTitle','value'],
    components:{
        'inline-svg':InlineSvg
    },
    computed: {
        show: {
            get () {
                return this.value
            },
            set (value) {
                this.$emit('input', value)
            }
        }
    },
    data() {
        return {
            rating:null
        }
    },
    mounted(){
        this.getRating();
    },
    methods: {
        getRating(){
            this.$axios.get('/resources/'+this.$route.params.resource_id+'/rating')
                .then(res=>{
                    this.rating = res.data.rating;
                })
                .catch(()=>{
                    this.rating = 0;
                })

        },
        setRating(){
            this.show = false
            let rating = {
                rating: this.rating
            }

            this.$axios.post('/resources/'+this.$route.params.resource_id+'/rating',rating)
                .then(res=>{
                    this.$emit('refetch',res);
                    this.$toasted.success('Resursa a fost evaluata')
                })
                .catch(err=>{
                    this.$toasted.error(err)
                })
        }
    }

}
</script>