<template>
    <v-carousel class="step__carousel" hide-delimiter-background :hide-delimiters="hideDots"> 
        <template v-for="(item, index) in data"> 
          <v-carousel-item v-if="(index + 1) % columns === 1 || columns === 1" 
                           :key="index"
          > 
            <v-row class="flex-nowrap" style="height:100%"> 
              <template v-for="(n,i) in columns"> 
                <template v-if="(+index + i) < data.length"> 
                  <v-col :key="i"> 
                    <v-sheet v-if="(+index + i) < data.length" height="100%">
                      <v-row class="fill-height"
                             align="center"
                             justify="center"
                      >
                        <div class="step__item" :class="{'step__item--placeholder':item.previews.length === 0}">
                            <img v-if="item.previews.length !== 0" v-auth-image="$axios.defaults.baseURL+item.previews[0].file" height="100%" width="100%"/>
                            <div class="step__item--hover">
                              <v-btn @click="goTo(item)" rounded elevation="0" color="#2E4DD457">
                                  <img src="/img/eye.png"/>
                                  <span>vizualizare</span>
                              </v-btn>
                              <v-btn rounded elevation="0" color="#2E4DD457">
                                  <img src="/img/pencil.png"/>
                                  <span>editare</span>
                              </v-btn>
                              <v-btn @click="saveIn(item)" rounded elevation="0" color="#2E4DD457">
                                  <img src="/img/save.png"/>
                                  <span>salveaza in</span>
                              </v-btn>  
                            </div>      
                        </div>
                      </v-row>
                    </v-sheet>
                  </v-col> 
                </template> 
              </template> 
            </v-row> 
          </v-carousel-item> 
        </template> 
        <move-resource-modal
            v-model="toggleMoveModal"
            :btnIcon="'save'" 
            :btnIconSize="24" 
            :title="'Salveaza In'" 
            :btnTitle="'Salveaza'"
            :selectedItem="selectedItem"
            :moveResource="false" 
        />
    </v-carousel>  
</template>
<script>
import MoveResource from '../../components/molecules/ResourceMoveToModal.vue';

export default {
    props:['data','columns','hideDots'],
    components:{
        'move-resource-modal':MoveResource
    },
    data(){
      return {
        toggleMoveModal:false,
        selectedItem:null,
      }
    },
    methods: {
      goTo(item){
        this.$router.push('/main/'+this.$account+'/resources/'+this.$route.params.resources_id+'/resource/'+item.id)
      },
      saveIn(item){
          this.selectedItem = item;
          this.toggleMoveModal=true;
      },
    }
}
</script>